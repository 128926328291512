.list-item-col-index {
  display: flex;
  width: 4rem;
  height: 4rem;
  margin: 5px;
  color: var(--clr-dkgray);
  background-color: var(--clr-ltgray);
}
.list-item-col-content {
  display: flex;
  width: 70%;
}
.list-item-col-index-ext {
  display: flex;
  width: 10%;
  min-height: 3rem;
  background-color: var(--clr-ltgray);
}
.list-item-col-content-ext {
  display: flex;
  width: 90%;
  min-height: 3rem;
  background-color: var(--clr-ltgray);
}
.list-item-col-content__primary {
  font-size: 1.1rem;
  font-weight: bold;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.list-item-col-content__secondary {
  font-size: 0.9rem;
  font-weight: lighter;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.list-item-content__sub-text {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
}
.list-item-col-content__default {
  font-size: 0.8rem;
  font-weight: lighter;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.list-item-2-col__1 {
  display: flex;
  width: 35%;
  padding-left: 1rem;
}
.list-item-2-col__2 {
  display: flex;
  flex-direction: column;
  width: 65%;
  padding-left: 1rem;
}
.list-item-4-col__index {
  display: flex;
  width: 20%;
  padding-left: 1rem;
}
.list-item-4-col__1 {
  display: flex;
  width: 25%;
  padding-left: 1rem;
}
.list-item-4-col__2 {
  display: flex;
  width: 35%;
  padding-left: 1rem;
}
.list-item-7-col__index {
  display: flex;
  width: 14%;
  padding: 0.1rem;
}
.list-item-col-action {
  display: flex;
  width: 20%;
}
@media (max-width: 768px) {
  .list-item-col-content {
    flex-direction: column;
    padding-left: 1.2rem;
  }

  .list-item-col-content__primary {
    font-size: 1rem;
    font-weight: bold;
  }
  .list-item-col-content__secondary {
    font-size: 0.75rem;
  }
  .list-item-2-col__1 {
    width: 100%;
    height: 50%;
    padding: 0rem;
    margin-top: 0.6rem;
  }
  .list-item-2-col__2 {
    width: 100%;
    height: 50%;
    padding: 0rem;
    margin-bottom: 0.6rem;
  }
  .list-item-4-col__1 {
    width: 100%;
    height: 50%;
    padding: 0rem;
    margin-top: 1rem;
  }
  .list-item-4-col__2 {
    width: 100%;
    height: 50%;
    padding: 0rem;
    margin-bottom: 1rem;
  }
  .list-item-4-col__index {
    display: none;
  }
}
@media (max-width: 644px) {
  .list-item-col-content-ext {
    flex-direction: column;
    -ms-flex-align: center !important;
    align-items: center !important;
    width: 100%;
  }
}
@media (max-width: 414px) {
  .list-item-col-content {
    width: 100%;
  }

  .list-item-col-action {
    display: none;
  }
}
