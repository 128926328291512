/*@font-face {
  font-family: primaryBold;
  src: url('../fonts/Futura/Futura Bold font.ttf');
}

@font-face {
  font-family: primaryRegular;
  src: url('../fonts/Futura/futura medium bt.ttf');
}*/

@font-face {
  font-family: primaryLight;
  src: url('../fonts/Maison neue font/HelveticaNeueLt.ttf');
}
@font-face {
  font-family: primaryRegular;
  src: url('../fonts/Maison neue font/HelveticaNeueMed.ttf');
}
@font-face {
  font-family: primaryBold;
  src: url('../fonts/Maison neue font/HelveticaNeueBd.ttf');
}

body {
  font-family: primaryLight;
  height: 100vh;
  background: white;
  overflow-x: hidden;
}
p ul {
  margin: 0px;
  padding: 0px;
  font-family: primaryRegular;
}
h1 h2 h3 h4 h5 h6 {
  margin: 0px;
  padding: 0px;
  font-family: primaryBold;
}
.pac-container {
  z-index: 1000000;
  width: 100%;
  position: absolute;
}
:root {
  /*--clr-primary: #34CBA8;*/
  --clr-red: #D2262C;
  --clr-dark-red: #B0040A;
  --clr-green: #8CC63F;
  --clr-dark-green: #6AA41D;
  --clr-amber: #FAD201;
  --clr-purple: #800080;
  --clr-gray: lightgray;
  --clr-ltgray: #f3f4f8;
  --clr-dkgray: darkgray;
  --clr-dark-gray: dimgray;

  --clr-primary: var(--clr-green);
  --clr-warning: var(--clr-red);
  --clr-secondary: var(--clr-gray);

  --time-short: 350ms;
  --time-medium: 550ms;
}

/* override bootstrap z-index to cater for burger default index */
.clr-primary {
  color: var(--clr-primary);
}
.clr-warning {
  color: var(--clr-warning);
}
.clr-secondary {
  color: var(--clr-secondary);
}

.clr-green {
  color: var(--clr-green);
}
.clr-gray {
  color: var(--clr-gray);
}
.clr-ltgray {
  color: var(--clr-ltgray);
}
.clr-amber {
  color: var(--clr-amber);
}
.clr-red {
  color: var(--clr-red);
}
.clickable:hover {
  cursor: pointer;
}
.text-style-car-plate {
  font-family: 'Segoe UI', Arial, sans-serif;
  color: var(--clr-dkgray);
}
.fixed-top {
  z-index: 100;
}
/* anim */
.anim-comp-m-enter {
  opacity: 0;
}

.anim-comp-m-enter-active {
  opacity: 1;
  transition: opacity var(--time-medium);
}

.anim-comp-m-exit {
  opacity: 1;
}

.anim-comp-m-exit-active {
  opacity: 0;
  transition: opacity var(--time-medium);
}
.anim-comp-s-enter {
  opacity: 0;
}

.anim-comp-s-enter-active {
  opacity: 1;
  transition: opacity var(--time-short);
}

.anim-comp-s-exit {
  opacity: 1;
}

.anim-comp-s-exit-active {
  opacity: 0;
  transition: opacity var(--time-short);
}

.anim-page-enter {
  opacity: 0.01;
  transform: translate3d(0, 30px, 0);
}

.anim-page-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all var(--time-short);
}

.anim-page-exit {
  opacity: 1;
  transform: scale(1);
}

.anim-page-exit-active {
  opacity: 0.01;
  transform: scale(1.1);
  transition: all var(--time-short);
}
/* */

/* global */
.app-main-debug--red {
  border: 1px solid red;
}
.app-main-debug--green {
  border: 1px solid green;
}
.app-main-debug--gray {
  border: 1px solid gray;
}
.app-pad--medium {
  padding: 8px 16px;
}
.app-pad--large {
  padding: 16px 32px;
}
.app-pos-float--right {
  float: right;
}
.app-pos-flex--space-between {
  justify-content: space-between;
}
.app-pos-relative {
  position: relative;
}
.app-pos-overlap {
  position: absolute;
}

.app-pos-overlap.pos__search-input {
  position: absolute;
  right: 10px;
  top: 10px;
}

.app__li--horizontal {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.app__li--horizontal li {
  display: inline-block;
  margin-right: 25px;
}
.app__img-120 {
  height: 100px;
}
.app__img--small-50 {
  height: 50px;
}
.app__img--small-30 {
  height: 30px;
}
.app__img--small-20 {
  height: 20px;
}
.app__img--small-10 {
  height: 10px;
}
.app-img__header--logo {
  height: 30px;
}
.app-img__site--logo {
  width: 88px;
  height: 50px;
  object-fit: contain;
}
.app__btn--medium {
  width: 120px;
  border: 1px solid #ccc;
  background: transparent;
  padding: 7px 0px;
  color: #3c3b3b;
  border-radius: 0px;
  font-size: 14px;
}
.app__btn--small {
  width: 52px;
  height: 36px;
  border: 1px solid #ccc;
}
/* burger specific */
.bm-burger-button {
  position: fixed;
  width: 34px;
  height: 28px;
  right: 32px;
  top: 24px;
}
.bm-burger-bars {
  background: dimgrey;
}
.bm-burger-bars:hover {
  background: var(--clr-primary);
}
.bm-menu {
  background: white;
  padding: 0;
  font-size: 1.15em;
}
/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  min-height: 4rem;
  text-decoration: none;
  color: gray;
  transition: color 0.2s;
}
.bm-item-ext {
  width: 100%;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
.bm-item-ext:hover {
  background-color: dimgray;
}
.bm-item-list {

}
/* Change color on hover */
.bm-item:hover {
  color: var(--clr-primary);
  font-weight: bolder;
  text-decoration: none;
  cursor: pointer;
}
.bm-item:focus {
  outline: none;
}
.bm-cross-button {
  height: 34px;
  width: 34px;
}
.bm-cross {
  background: black;
}
/* page specific */
.app-main__header--color {
  background: white;
}
.app-main__header-tab-section {
  min-height: 18px;
}
.app-main__header-title {
  color: dimgray;
  font-size: 1.4rem;
  font-weight: bolder;
}
.app-main__header-tab {
  margin: 0px;
  padding: 0px;
}
.app-item-underline-border {
  border-bottom: 1px solid #e5e5e5;
}
.marker-mandatory {
  font-size: 1em;
  font-weight: bold;
  color: var(--clr-warning);
}
/*
.app-main__header-tab li {
  margin-right: 50px;
  padding-bottom: 10px;
  cursor: pointer;
}

.app-main__header-tab li a {
  color: #000;
}
*/
/*
.app-main__search-input {
  border-radius: 20px;
  box-shadow: none;
  border: 1px solid #e2e2e2;
  padding: 8px 16px;
  width: 300px;
}
.app-main__search-input:focus {
  box-shadow: none;
  outline: none;
}
*/
.app-main__side-menu {
  padding: 0px;
  margin: 0px;
}
.app-main__side-menu ul {
  padding: 0px;
  margin: 0px;
}
.app-main__side-menu li {
  list-style: none;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
}
.app-main__body {
  flex-direction: row;
}
.app-main__body-navi {
}
.app-main__body-content {
}
.app-main__content {
  flex-direction: row;
  max-width: 1280px;
}
.app-main__content__card {
  margin-bottom: 1rem;
}
.li__side-menu--no-focus {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 10px 30px;
  color: black;
}
.li__side-menu--no-focus:hover {
  color: var(--clr-primary);
}
.li__side-menu--focus {
  background: #ebf0f3;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 10px 30px;
  color: var(--clr-primary);
}
.nav-mobile {
  display: none;
}
.nav-desktop {
  display: block;
}
.nav-dropdown-override {
  font-size: 1.25rem;
  color: var(--clr-primary);
  text-decoration: none;
}
.nav-dropdown-override:hover {
  color: var(--clr-primary);
  text-decoration: none;
}
.navi-side-menu-override {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.navi-side-menu-link-override:hover {
  text-decoration: none;
}
.navi-side-menu-link {
  margin-bottom: 0.33rem;
}
.nav-item--override {
  margin-bottom: 0px;
}
.nav-link-override {
  color: black;
}
.nav-link-override:hover {
  color: var(--clr-primary);
  text-decoration: none;
}
.li__tab-menu-item--focus {
  color: var(--clr-primary);
  border-bottom: 2px solid var(--clr-primary);
}
.li__tab-menu-item--focus:hover {
  color: var(--clr-primary);
}
.app-main__side-menu-content {
  padding-left: 280px;
}
.input-css {
  display: block;
  width: 100%;
  background: #fff;
  height: 40px;
  border: 1px solid #efefef;
  padding: 5px;
}

.input-css:focus {
  box-shadow: none;
  outline: 0px;
}

.box {
  position: absolute;
  left: 50%;
  top: 140px;
  width: 25%;
  transform: translate(-50%, -50%);
}

label {
  font-size: 13px;
  color: #4e4e50;
}
.label_ext {
  font-size: 13px;
  color: #4e4e50;
}
.btn {
  background: var(--clr-primary);
  border: 1px solid var(--clr-primary);
  color: #fff;
}
.btn-invert {
  background: #fff;
  border: 1px solid lightgrey;
  border-radius: 4px;
  color: dimgrey;
}
.btn-invert:focus {
  outline: none;
}
/*
.btn-invert:hover {
  border-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: var(--clr-primary) auto 5px;
}*/
.btn:hover {
  background: white;
  border: 1px solid var(--clr-primary);
  color: var(--clr-primary);
  cursor: pointer;
}
.btn__disabled {
  background: white;
  border: 1px solid var(--clr-secondary);
  color: var(--clr-secondary);
}
.btn__disabled:hover {
  background: var(--clr-secondary);
  border: 1px solid var(--clr-secondary);
  color: white;
}
.btn-sign-in {
  width: 100%;
  margin-top: 20px;
  padding: 10px 20px;
}
.btn-default {
  width: 160px;
  height: 38px;
  padding: 7px 20px;
}
.btn-default-small {
  width: 100px;
  height: 38px;
  padding: 7px 20px;
}
.range-slider__range {
  width: 100%;
  margin: 10px 0px;
}

.height {
  height: 30px;
}

.psr {
  position: relative;
  top: 7px;
  left: 10px;
}

.table-parking tbody {
  border: 1px solid #e0e0e0;
}

.list-set {
  padding: 0px;
  margin: 0px;
}

.list-set li {
  display: inline-block;
  margin-right: 15px;
}

.table-parking tr td {
  padding: 15px;
}
.table-parking tr {
  border-bottom: 1px solid #e8e8e8;
}

.table-parking tr td:first-child {
  background: #f3f4f8;
  text-align: center;
}

.table-parking tr td h6 {
  font-weight: bold;
  margin: 0px;
}

.table-parking tr td p {
  font-size: 13px;
  margin: 0px;
  white-space: nowrap;
}

.btn-view {
  border: 1px solid #e0e0e0;
  color: #464646;
  font-size: 12px;
  padding: 7px 30px;
  background: transparent;
}

.fa-trash-alt {
  color: var(--clr-dark-red);
  cursor: pointer;
  position: relative;
  top: 2px;
}
.fa-pencil-alt {
  color: var(--clr-dark-gray);
  cursor: pointer;
  position: relative;
  top: 2px;
}
.fa-concierge-bell {
  color: var(--clr-dark-gray);
  cursor: pointer;
  position: relative;
  top: 2px;
}
.fa-concierge-bell-none {
  opacity: 0.15;
}
input[type='time'] {
  border: 1px solid #ccc;
  height: 38px;
}

.nine {
  position: absolute;
  top: 39px;
  left: 20px;
}

.ts {
  padding: 0px;
  margin: 0px;
}

.ts li {
  display: inline-block;
  font-size: 14px;
  margin-right: 25px;
}

.trs td {
  padding: 5px !important;
}
@media (min-width: 600px) {
  .navi-side-menu-link-max-width {
    max-width: 12rem;
  }
}
@media (max-width: 1078px) {
  .nav-mobile {
    display: block;
  }
  .nav-desktop {
    display: none;
  }
  .navi-side-menu-override {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .navi-side-menu-link {
    min-width: 8rem;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }
  .li__side-menu--no-focus,
  .li__side-menu--focus {
    text-align: center;
    border-radius: 12px;
    border: 0.6px solid #e0e0e0;
    margin-left: 1rem;
  }

  .app-img__header--logo {
    height: 20px;
  }
  .app-main__body {
    flex-direction: column;
  }
  .app-main__body-navi {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .app-main__body-content {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .app-main__side-menu-content {
    padding-left: 1rem;
  }
  .app-main__content {
    flex-direction: column;
  }
  .app-main__content__card {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
